<template lang="pug">
    .main-wrapper.funcionario-listar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-12.ta-right
                    h1.text-header.text-secondary MedClub / <b>Recarregue</b>

        Dialog.dialogApagar(header='Remover usuário' :visible.sync='dialogApagar' :modal='true')
            p Deseja remover o usuário <b>{{ dialogApagar_data.first_name }} {{ dialogApagar_data.last_name }}</b>?
            .ta-center.my-4
                ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
                Button.p-button-danger(v-else label='Remover' @click='remove(dialogApagar_data.id)')

        Dialog.dialogAdicionar(header='Adicionar créditos' :modal='true' :visible.sync='dialogAdicionar')
                .p-grid.p-fluid
                    .p-col-5
                        label.form-label Método:
                        Dropdown(
                            v-model='model.cd_forma_pagamento'
                            :options='options.formasPagamento'
                            optionLabel='text'
                            optionValue='value'
                            dataKey='value'
                        )
                    .p-col-7
                        label.form-label Valor:
                        .p-inputgroup
                            span.p-inputgroup-addon R$
                            span
                                currency-input.input-adicionar.p-inputnumber-input.p-inputtext.p-component.p-filled(
                                    v-model='model.nr_valor_adicionar'
                                    locale='pt-BR'
                                    :auto-decimal-mode='true'
                                    :value-range='{ min: 0 }')
                    .p-col-12.ta-center
                        Button(label='Adicionar' icon='jam jam-plus' @click='adicionar()')

        div(v-if='gerouBoleto')
            ProgressBar(v-if='waitingGerar' mode="indeterminate")

            div(v-else-if='model.cd_forma_pagamento == "pix"')
                .p-4.w-full.mb-4
                transition(name='p-message' appear='')
                    div.pa-2(:class='containerClass' v-show='true' role='success')
                        .p-message-wrapper
                        span(class='pi pi-info-circle')
                        span(style='font-size: 17px; margin-left: 5px; font-weight: bold;') Pix gerado
                        .p-message-text
                            span Após o pagamento, o valor ficará disponível na sua conta.

                .p-grid.p-fluid.p-justify-center.mt-2
                    .p-col-12.p-md-4(v-for='elem, idx in list' :key='idx')
                        div.payment-box
                            .ta-center
                                //- div.icon-box(v-if='elem.cd_forma_pagamento == 1')
                                //-     i.pi.pi-money-bill
                                //- div.icon-box(v-if='elem.cd_forma_pagamento == 2')
                                //-     i.jam.jam-credit-card-f
                                //- div.icon-box(v-if='elem.cd_forma_pagamento == 3')
                                //-     i.jam.jam-envelope
                                //- div.icon-box(v-if='elem.cd_forma_pagamento == 4')
                                //-     i.jam.jam-phone
                                p.text-method Valor
                                p.text-value {{ formatPrice(model.nr_valor_adicionar) }}

                .p-grid.p-fluid.p-justify-center.mt-2
                    p Esse código expira em&nbsp;
                    p(style='text-size: 18px; font-weight: bold; color: #00A02E; margin-left: 5px;') {{ getTimer() }}

                .p-fluid.ta-center.mt-2
                    vue-qrcode(:value='boletoData.pix_qr_code' :options="{ width: 200 }")
                    br
                    input(id='codigo_barras' readonly :value='boletoData.pix_qr_code' style='border-width: 0px; font-size: 16px; text-align: center; font-weight: bold;')

                .p-grid.p-fluid.p-justify-center.mt-2
                    .p-col-6.p-md-3
                        Button(icon='jam jam-files' label='Copiar Código' type="submit" @click="copyCodigo()")

                //-     .p-col-6.p-md-3
                //-         Button(icon='jam jam-files' label='Ver Boleto' type="submit" @click="openBoleto()")

            div(v-else-if='model.cd_forma_pagamento == "boleto"')
                .p-4.w-full.mb-4
                transition(name='p-message' appear='')
                    div.pa-2(:class='containerClass' v-show='true' role='success')
                        .p-message-wrapper
                        span(class='pi pi-info-circle')
                        span(style='font-size: 17px; margin-left: 5px; font-weight: bold;') Boleto gerado
                        .p-message-text
                            span Após o pagamento, o valor ficará disponível na sua conta em até 3 dias úteis.

                .p-grid.p-fluid.p-justify-center.mt-2
                    .p-col-12.p-md-4(v-for='elem, idx in list' :key='idx')
                        div.payment-box
                            .ta-center
                                div.icon-box(v-if='elem.cd_forma_pagamento == 1')
                                    i.pi.pi-money-bill
                                div.icon-box(v-if='elem.cd_forma_pagamento == 2')
                                    i.jam.jam-credit-card-f
                                div.icon-box(v-if='elem.cd_forma_pagamento == 3')
                                    i.jam.jam-envelope
                                div.icon-box(v-if='elem.cd_forma_pagamento == 4')
                                    i.jam.jam-phone
                                p.text-method Valor
                                p.text-value {{ formatPrice(model.nr_valor_adicionar) }}

                .p-fluid.ta-center.mt-2
                    span Código de Barras
                    br
                    input(id='codigo_barras' readonly :value='boletoData.boleto_barcode' style='border-width: 0px; font-size: 16px; text-align: center; font-weight: bold;')

                .p-grid.p-fluid.p-justify-center.mt-2
                    .p-col-6.p-md-3
                        Button(icon='jam jam-files' label='Copiar Código' type="submit" @click="copyCodigo()")

                    .p-col-6.p-md-3
                        Button(icon='jam jam-files' label='Ver Boleto' type="submit" @click="openBoleto()")


        div(v-else)
            div(v-if='!waitingSaldo')
                .p-4.w-full.mb-4
                    transition(name='p-message' appear='')
                        div.pa-2(:class='containerClass' v-show='true' role='alert')
                            .p-message-wrapper
                            span(class='pi pi-info-circle')
                            span(style='font-size: 17px; margin-left: 5px; font-weight: bold;') Recarregue com boleto
                            .p-message-text
                                span Agora você pode adicionar créditos via boleto para pagar suas consultas e exames com facilidade e segurança.

                .p-grid.p-fluid.p-justify-center
                    .p-col-12.p-md-4(v-for='elem, idx in list' :key='idx')
                        div.payment-box
                            .ta-center
                                div.icon-box(v-if='elem.cd_forma_pagamento == 1')
                                    i.pi.pi-money-bill
                                div.icon-box(v-if='elem.cd_forma_pagamento == 2')
                                    i.jam.jam-credit-card-f
                                div.icon-box(v-if='elem.cd_forma_pagamento == 3')
                                    i.jam.jam-envelope
                                div.icon-box(v-if='elem.cd_forma_pagamento == 4')
                                    i.jam.jam-phone
                                p.text-method {{ elem.cd_forma_pagamento_f }}
                                p.text-value {{ elem.nr_valor_f }}

                .p-grid.p-fluid.p-justify-center.mt-2
                    .p-col-6.p-md-4
                        Button(label='Adicionar Créditos' v-tooltip.top="'Adicione créditos à sua conta'" icon="jam jam-coin" type="submit" @click="dialogAdicionar = true")

                Panel.mt-3.datatable(header='Extrato')
                        DataTable(:value="extrato")
                            Column(headerStyle='width: 20%;' field='id' header='Código')
                            Column(headerStyle='width: 20%;' field='dt_data' header='Data')
                                template(#body='props')
                                    span(v-tooltip.top="props.data.dt_data" style='cursor: pointer;') {{ props.data.dt_data_f }}
                            Column(headerStyle='width: 20%;' field='nr_valor' header='Valor')
                                template(#body='props')
                                    span(v-tooltip.top="props.data.nr_valor" style='cursor: pointer;') {{ formatPrice(props.data.nr_valor) }}
                            Column(headerStyle='width: 15%;' field='ie_status' header='Status')
                            //- Column(headerStyle='width: 20%;' field='estabelecimentoauthuser__ds_setor' header='Setor' sortable)
                            //-     template(#body='props') {{ props.data.ds_setor }}
                            //- Column(headerStyle='width: 10%; text-align: center')
                            //-     template(#header) Ações
                            //-     template(#body='props')
                            //-         .ta-center
                            //-             Button.p-button-raised.p-button-rounded.mr-1(
                            //-                 v-tooltip.top="'Editar'"
                            //-                 icon="jam jam-write"
                            //-                 @click="$router.push(`/revendedor/salvar/${ props.data.id }/`)"
                            //-             )
                            //-             Button.p-button-raised.p-button-rounded.p-button-danger(
                            //-                 v-tooltip.top="'Apagar'"
                            //-                 icon="jam jam-minus-circle"
                            //-                 @click="dialogApagar_data = props.data; dialogApagar = true"
                            //-             )
                        Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

            div(v-else)
                ProgressBar(v-if='waitingSaldo' mode="indeterminate")
            //- ProgressBar(v-if='waiting' mode="indeterminate")
            //- div(v-else-if='list.length == 0')
            //- 	p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
            //- div(v-else)
            //- DataView.dataview.my-2(:value="list" layout="grid")
            //- 	template(#grid="props")
            //- 		.p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.is_active }")
            //- 			Panel.panel-list.ta-center(:header="`${ props.data.first_name } ${ props.data.last_name }`")
            //- 				.ta-left
            //- 					p <b>Nome:</b> {{ props.data.first_name }}
            //- 					p <b>Sobrenome:</b> {{ props.data.last_name }}
            //- 					p <b>E-mail:</b> {{ props.data.email }}
            //- 					p <b>Usuário:</b> {{ props.data.username }}
            //- 				.ta-right.p-buttonset.mt-3
            //- 					Button.mr-1.p-button-raised.p-button-rounded(
            //- 						icon='jam jam-write'
            //- 						@click="$router.push(`/revendedor/salvar/${ props.data.id }/`)"
            //- 					)
            //- 					Button.p-button-raised.p-button-rounded.p-button-danger(
            //- 						v-if='props.data.is_active' icon='jam jam-minus-circle'
            //- 						@click="dialogApagar_data = props.data; dialogApagar = true"
            //- 					)
            //- Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

</template>

<style lang="scss">
    .funcionario-listar {
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
            .cell {
                padding: 16px 0;
                text-align: center;
                &.ex  {  background-color: #e4f8e1; }
                &.a  { background-color: #faf3dd; }
            }
        }
        .dialogApagar {
            .p-progress-spinner {
                width: 29px;
                height: auto;
            }
        }
        .p-message-wrapper {
            display: flex;
            align-items: center;
        }
        .p-message-close {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .p-message-close.p-link {
            margin-left: auto;
            overflow: hidden;
            position: relative;
        }
        .p-message-enter-from {
            opacity: 0;
        }
        .p-message-enter-active {
            transition: opacity .3s;
        }
        .p-message.p-message-leave-from {
            max-height: 1000px;
        }
        .p-message.p-message-leave-to {
            max-height: 0;
            opacity: 0;
            margin: 0 !important;
        }
        .p-message-leave-active {
            overflow: hidden;
            transition: max-height .3s cubic-bezier(0, 1, 0, 1), opacity .3s, margin .15s;
        }
        .p-message-leave-active .p-message-close {
            display: none;
        }
        .dialogAdicionar {
			width: 96%;
			max-width: 400px;
			.p-inputnumber-input {
				text-align: right;
			}
			.p-dialog-content {
				overflow: visible !important;
			}
		}
        .payment-box {
            .text-method {
                font-size: 18px;
                margin: 0;
                margin-top: 14px;
            }
            .text-value {
                font-size: 22px;
                font-weight: 700;
                margin: 0;
                margin-top: 10px;
            }
            .icon-box {
                width: 100px;
                height: 100px;
                line-height: 130px;
                border-radius: 50%;
                display: inline-block;
                background-color: #312c66;
                i { font-size: 44px; color: #fff; }
            }
            .btn-remove-payment {
                display: inline-block;
                background-color: #c8c8c8;
                width: 20px;
                height: 20px;
                text-align: center;
                line-height: 16px;
                border-radius: 50%;
                i { font-size: 20px; color: #666; }
            }
        }
        .disabled {
            p { color: #aaa; }
            .p-panel-titlebar {
                span { color: #aaa; }
            }
            .p-panel-content {
                background-color: #f4f4f4 !important;
            }
        }
    }
    .qrcodeCarteiraWrapper {
        position: absolute;
        padding: 2.2%;
        width: 25%;
        top: 16%;
        right: 5.4%;
        > img {
            width: 100% !important;
            height: auto !important;
        }
    }
    .textWrapper {
        position: absolute;
        bottom: 0;
        left: 0;
    }
    .p-tooltip {
        max-width: none;
    }
</style>

<script>
    import DataView from 'primevue/dataview'
    import Panel from 'primevue/panel'
    import Button from 'primevue/button'
    import ProgressBar from 'primevue/progressbar'
    import Dialog from 'primevue/dialog'
    import Dropdown from 'primevue/dropdown';
    import Tooltip from 'primevue/tooltip'
    import InputText from 'primevue/inputtext'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Card from 'primevue/card'
    import Paginator from 'primevue/paginator'
    import Message from 'primevue/message'
    import ProgressSpinner from 'primevue/progressspinner'
    import moment from 'moment';
    import { Funcionario, Empresa } from './../../middleware'
    import wsConfigs from './../../middleware/configs'
    import VueQrcode from '@chenfengyuan/vue-qrcode';
    export default {
        created () {
            this.applyFilters()
            this.options.formasPagamento.push({ value: '', text: '- SELECIONE - ' })
            this.options.formasPagamento.push({ value: 'boleto', text: 'Boleto Bancário' });
            this.options.formasPagamento.push({ value: 'pix', text: 'Pix' });
            this.model.cd_forma_pagamento = '';

            // this.gerouBoleto = true;
            // this.waitingGerar = false;
        },
        unmounted() {
            window.clearInterval(this.intervalTimer)
        },
        deactivated() {
            window.clearInterval(this.intervalTimer)
        },
        components: {
            DataView, VueQrcode, Panel, Card, Button, ProgressBar, Dialog, InputText,
                Paginator, DataTable, Column, Message, Dropdown, Tooltip, ProgressSpinner
        },
        directives: { tooltip: Tooltip },
        data () {
            return {
                list: [],
                extrato: [],
                dialogApagar: false,
                dialogAdicionar: false,
                windowInnerWidth: window.innerWidth,
                dialogApagar_data: {},
                waiting: false,
                gerouBoleto: false,
                waitingApagar: false,
                waitingGerar: false,
                waitingSaldo: true,
                timer: null,
                boletoData: [],
                filters: {
                    name: ''
                },
                model: {
					cd_forma_pagamento: null,
                    nr_valor_adicionar: 0,
				},
				options: {
					formasPagamento: []
				},
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                },
                order: {
                    field: 'first_name',
                    sortOrder: 1
                }
            }
        },
        computed: {
            containerClass() {
                return 'p-message p-component p-message-info';
            }
        },
        methods: {
            formatPrice (val) {
                return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
            },
            getExtrato() {
                Empresa.getExtrato()
                .then(response => {
                    if([200, 201].includes(response.status)) {
                        response.data.forEach(extrato => {
                            if(extrato.dt_data) {
                                extrato.dt_data_f = moment(extrato.dt_data).format('DD/MM/YYYY')
                            }
                        })
                        this.extrato = response.data
                    }
                })
            },
            getSaldo() {
                Empresa.getSaldo()
                .then(response => {
                    if ([200, 201].includes(response.status)) {
                        this.list.push({
                            cd_forma_pagamento: 1,
                            cd_forma_pagamento_f: 'Créditos Medclub',
                            nr_valor: response.data.saldo,
                            nr_valor_f: this.formatPrice(response.data.saldo)
                        })
                        this.waitingSaldo = false;
                    }
                })
            },
            // getList (params) {
            //     let vm = this
            //     vm.waiting = true
            //     Funcionario.findAll(params).then(response => {
            //         if (response.status == 200) {
            //             this.paginator.count = response.data.count
            //             response.data.results.forEach(funcionario => {
            //                 if (funcionario.email) {
            //                     let strMaxLength = 44
            //                     funcionario.email_f = funcionario.email.length > strMaxLength ?
            //                         `${ funcionario.email.substr(0, strMaxLength) }...` : funcionario.email
            //                 }
            //             })
            //             vm.list = response.data.results
            //         }
            //         vm.waiting = false
            //     })
            // },
            openBoleto() {
                window.open(this.boletoData.boleto_url, '_blank').focus();
            },
            copyCodigo() {
                let copyText = document.getElementById('codigo_barras');
                copyText.select();
                document.execCommand("copy");
                this.$toast.info('Código copiado', { duration: 3000 })
            },
            adicionar() {
                if(this.model.cd_forma_pagamento !== null && this.model.cd_forma_pagamento !== '') {
                    this.dialogAdicionar = false;
                    this.gerouBoleto = true;
                    this.waitingGerar = true;
                    this.waitingSaldo = true;
                    this.gerarBoleto();
                } else {
                    this.$toast.error('Selecione a forma de pagamento.', { duration: 3000 });
                }
            },
            getTimer () {
                let minutes = `${ Math.floor(this.timer / 60) > 9 ? Math.floor(this.timer / 60) : '0' + Math.floor(this.timer / 60) }`
                let seconds = `${ (this.timer % 60) > 9 ? (this.timer % 60) : '0' + (this.timer % 60) }`
                return `${ minutes }:${ seconds }`
            },
            gerarBoleto() {
                if(this.model.cd_forma_pagamento == 'boleto') {
                    let dataSend = {
                        valor: this.model.nr_valor_adicionar,
                        cd_forma_pagamento: 1
                    }
                    Empresa.addCreditos(dataSend).then(response => {
                        this.waitingGerar = false;
                            if([200, 201].includes(response.status)) {
                                this.$toast.success('Boleto gerado com sucesso!', { duration: 3000 });
                                this.boletoData = response.data.result.body;
                                this.gerouBoleto = true;
                                this.waitingGerar = false;
                                this.waitingSaldo = false;

                            } else if(response.status !== 500) {
                                this.$toast.error(response.data.detail, { duration: 3000 });
                                this.dialogAdicionar = false;
                                this.gerouBoleto = false;
                                this.waitingGerar = false;
                                this.waitingSaldo = false;
                        }
                    })
                } else if(this.model.cd_forma_pagamento == 'pix') {

                    let dataSend = {
                        valor: this.model.nr_valor_adicionar,
                        cd_forma_pagamento: 3,
                    }

                    Empresa.addCreditos(dataSend).then(response => {
                        this.waitingGerar = false;
                            if([200, 201].includes(response.status)) {
                                this.$toast.success('Pix gerado com sucesso!', { duration: 3000 });
                                this.boletoData = response.data.result.body;
                                this.gerouBoleto = true;
                                this.waitingGerar = false;
                                this.waitingSaldo = false;

                                let expirationDate = moment(response.data.result.body.pix_expiration_date)

                                const now = moment()
                                let timer = expirationDate.diff(now, 'seconds')

                                if (timer > 0) {
                                    this.timer = timer
                                    window.setTimeout(() => {

                                        // let canvas = document.getElementById('canvas')
                                        // if (canvas) qrcode.toCanvas(canvas, this.dadosPix.pix_qrcode, err => {
                                        //     if (err) console.log(err)
                                        // })

                                        this.intervalTimer = window.setInterval(() => {
                                            if (this.timer > 0) {
                                                this.timer--
                                            } else if (this.timer <= 0) {
                                                window.clearInterval(this.intervalTimer)
                                                this.$toast.info('Pix expirado!', { duration: 3000 });
                                                this.dialogAdicionar = false;
                                                this.gerouBoleto = false;
                                                this.waitingGerar = false;
                                                this.waitingSaldo = false;
                                            }
                                        }, 1000)

                                    })
                                }

                            } else if(response.status !== 500) {
                                this.$toast.error(response.data.detail, { duration: 3000 });
                                this.dialogAdicionar = false;
                                this.gerouBoleto = false;
                                this.waitingGerar = false;
                                this.waitingSaldo = false;
                        }
                    })
                }
            },
            applyFilters () {
                // let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
                // params.order = `${ this.order.sortOrder == -1 ? '-' : '' }${ this.order.field }`
                // if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
                // Object.keys(this.filters).forEach((key) => { if (this.filters[key]) params[key] = this.filters[key] })
                // this.getList(params)
                this.getSaldo();
                this.getExtrato();
                // let id = 0
                // if (! isNaN(this.$route.params.id)) id = parseInt(this.$route.params.id)
            },
            checkEmptyField (field) {
                if (! this.filters[field].length) this.applyFilters()
            },
            onPage (ev) {
                this.paginator.page = ev.page + 1
                this.applyFilters()
            },
            onSort(ev) {
                this.order.field = ev.sortField
                this.order.sortOrder = ev.sortOrder
                this.applyFilters()
            },
            remove (id) {
                this.waitingApagar = true
                Funcionario.remove(id).then(response => {
                    this.waitingApagar = false
                    if (response.status == 200) {
                        this.$toast.info('Usuário removido', { duration: 3000 })
                        this.applyFilters()
                        this.dialogApagar = false
                    }
                })
            }
        }
    }
</script>
